import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment, urlMsCR } from '@environment';
import { TracesService } from 'src/app/core/services/traces/traces.service';
import {
    SURAPassConsultaTransaccionesAsesorJson,
    SURAPassConsultaTransaccionesClienteJson,
    SURAPassDesbloqueoDispositivoJson,
    SURAPassDesEnrolaDispositivoJson,
    SURAPassTransaccionJson,
} from '../../models/surapass/surapass.modelo';

@Injectable({
    providedIn: 'root',
})
export class SurapassService {
    //CONSTANTES PUBLICAS
    public STORAGE_SURAPASS_ULTIMA_TRANSACCION: string = 'SURAPassUltimaTransaccion';

    constructor(private readonly http: HttpClient, private tracesService: TracesService) {}

    /**
     * Consulta Dispositivo
     * @param rut rut
     * @returns
     */
    public async getDispositivoActivo(rutcliente: string): Promise<any> {
        const endpoint = `${urlMsCR}/datos-transaccionales/surapass/dispositivo-activo`;
        return await this.http.get(endpoint, { params: {rut:rutcliente} }).toPromise()
        .then((data: any) => {
          return data.data;
        });
    }

    /**
     * Recupera Historial de Dispositivos
     * @param rutcliente
     * @returns
     */
    public async getDispositivoHistorial(rutcliente: string): Promise<any> {
        const params ={rut: rutcliente};
        const endpoint = `${urlMsCR}/datos-transaccionales/surapass/historial-dispositivo`;
        return await this.http.get(endpoint,{params}).toPromise()
        .then((data: any) => {
            return data.data;
        });
    }

    /**
     * Grabar la solicitud en en la base de datos
     * @param parametros prametros
     * @returns
     */
    public async postCrearTransaccion(convenio: number, parametros: SURAPassTransaccionJson): Promise<any> {
        const endpoint = `${urlMsCR}/datos-transaccionales/surapass/crear-transaccion/${convenio}`;
        return await this.http.post(endpoint, parametros).toPromise()
        .then((data: any) => {
            return data.data;
        });
    }

    /**
     * Consulta el estado de una transaccion
     * @param parametros aplicacion origen
     * @returns
     */
    public async getEstadoTransaccion(numerotransaccion: number, aplicacion: string): Promise<any> {
        const params ={numerotransaccion: String(numerotransaccion), aplicacion: aplicacion};
        const endpoint = `${urlMsCR}/datos-transaccionales/surapass/estado-transaccion`;
        return await this.http.get(endpoint,{params}).toPromise()
        .then((data: any) => {
            return data.data;
        });
    }

    /**
     * Desenrola un dispositivo
     * @param parametros datos del desenrolamiento
     * @returns
     */
    public async putDesenrolarDispositivo(parametros: SURAPassDesEnrolaDispositivoJson): Promise<any> {
        const endpoint = `${urlMsCR}/datos-transaccionales/surapass/desenrolar-dispositivo`;
        return await this.http.put(endpoint, parametros).toPromise()
        .then((data: any) => {
            return data.data;
        });
    }

    /**
     * Desbloquea un dispositivo
     * @param parametros datos del desenrolamiento
     * @returns
     */
    public async putDesbloquearDispositivo(parametros: SURAPassDesbloqueoDispositivoJson): Promise<any> {
       const endpoint = `${urlMsCR}/datos-transaccionales/surapass/desbloquear-dispositivo`;
       return await this.http.put(endpoint, parametros).toPromise()
       .then((data: any) => {
           return data.data;
       });
    }

    /**
     * Consulta de Transacciones Asesor
     * @param parametros datos de consulta asesor
     * @returns
     */
    public async getTransaccionesAsesor(parametros: SURAPassConsultaTransaccionesAsesorJson): Promise<any> {
        const  params={
            nombreEjecutivoSolicitante: parametros.nombreEjecutivoSolicitante,
            cantidadDiasAtras: String(parametros.cantidadDiasAtras),
        }
        const endpoint = `${urlMsCR}/datos-transaccionales/surapass/transaccion-asesor`;
        return await this.http.get(endpoint,{params}).toPromise()
        .then((data: any) => {
            return data.data;
        });
    }

    /**
     * Consulta de Transacciones Cliente
     * @param parametros datos de consulta cliente
     * @returns
     */
    public async getTransaccionesCliente(parametros: SURAPassConsultaTransaccionesClienteJson): Promise<any> {
        const params={
            idToken: parametros.idToken,
            rut: parametros.rut,
            tipoConsulta: parametros.tipoConsulta,
        }
        const endpoint = `${urlMsCR}/datos-transaccionales/surapass/transaccion-cliente`;
        return await this.http.get(endpoint,{params}).toPromise()
        .then((data: any) => {
            return data.data;
        });
    }

    /**
     * Consulta de Transaccion Detalle
     * @param numerotransaccion numero de transaccion en ser consultada
     * @returns
     */
    public async getTransaccionDetalle(numerotransaccion: number): Promise<any> {
        const  params={
            idTransaccion: String(numerotransaccion),
        }
        const endpoint = `${urlMsCR}/datos-transaccionales/surapass/detalle-transaccion`;
        return await this.http.get(endpoint,{params}).toPromise()
        .then((data: any) => {
            return data.data;
        });
    }

    /**
     * Consulta Configuracion SURAPass
     * @returns
     */
    public async getConfiguracion(): Promise<any> {
        const endpoint = `${urlMsCR}/datos-transaccionales/surapass/configuracion`;
        return await this.http.get(endpoint).toPromise()
        .then((data: any) => {
            return data.data;
        });
    }
}
