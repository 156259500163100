export const Url = 'https://bff.asesor2.inversiones.sura.cl';
export const UrlCR = "https://gateway-nwsc.privado.inversiones.sura.cl";
export const urlMsCR = "https://ms-nwsc.privado.inversiones.sura.cl";
export const ClientUrl = 'https://privado.inversiones.sura.cl';
export const ClientBFF = 'https://gateway.privado2.inversiones.sura.cl';
export const environment = {
    MODIFICACION_FICHA_CLIENTE: false,
    versionCheck: './version.json',
    production: true,
    name: 'PROD',
    TIMEOUT_LAST_SESSION: 5000,
    KEYS: {
        GOOGLE: {
            RECAPTCHA_V3_SITE_KEY: '6LcMil0pAAAAACRnoihsNh9_5jhq_NEbgkNMHwLU',
            TAG_MANAGER_ID: 'GTM-KBWPHSS',
        },
        CONTENTFUL: {
            SPACE_ID: "n0yk1slbvfif",
            TOKEN: "Jofe0NuMhwbLyNBkSx1I7Qp8VFLX7h2DsfLRnh3cl3k",
            LIST_INTERNAL_ID: 'aSYi984DjSJQFFLJlMQmO',
            SURA_ACTIONS_EXECUTIVE: '7vgeKEzSumbkjpkhBCMpAG',
            CONFIG_MESSAGE_EXECUTIVE_WITH_PORTFOLIO: 'vgzRfc4SBoPsXwSPD6pVO',
            CONFIG_MESSAGE_EXECUTIVE_WITHOUT_PORTFOLIO: '5H0CAMSg7LH5xHybpP5AXb',
            FILTER_LOGROCKET_BY_ENV: '1ueKDCcEXIm2sw14PQgYIz',
            LINKS_ORDER_ENTRY: "78FKWtF6dLOGBNAAmXnBR0",
            PAYMENT_PLAN: "1qOsM25Qw9sFqSkmlGYTMg",
            CONFIG_VALOR_CUOTA_SHOW_ACCESS: '3CFx8wtJuKkyEQjLKYWF38',
            UPDATE_CONTACTABILITY: 'fV3ESueXeG4h8Jo9b6rw0',
            TOKEN_DATA_CONTENTFUL: '6fQQfV2JAAcfVln8SJEbp5',
            CERTIFICADO_SALDOS_SHOW: '6zV1JhHRDEckdz2x6rt6X8',
            CIRCULARIZACION: '4pxfjjM6p36bF1Uon6Gpl2',
            CERTIFICADO_SALDOS_MENSUAL: '5UnOP0zBOXt187j7BA33ui',
            HABILITAR_MULTITABS: '6CqBtj52VX6uWapBfK118Y',
            HABILITAR_ASSET_ALLOCATION: '1rAfmzS62YCnmVuYgdYuHy',
            HABILITAR_SALDO_POR_FALLECIMIENTO: '7len8salE0j3opCitsByd8',
            HABILITAR_LOGO_NUEVO_SURA: 'uYXe6UsTpHaXd8cj5LYSN'
        },
        CMSRUTERO: {
            DOCUMENT_PLANAPV: ['jZBM9fMyqAyFQoVMgWsx', 'nTLC4zzfIMmGxzlAhZM3', 'FPqTSTFSnQC75hBibClp'],
            DOCUMENT_CCG: ['0UFrWSVwObWctWvU4Dbm', 'Q8Q02ZIP1bwt7XzB1sKa', 'FPqTSTFSnQC75hBibClp'],
            DOCUMENT_TICKETCLIENT: ['pLgL5AoxJZ9OAMWjw0ow', 'gL186svkbM0cZf36D0Dp', 'hFyBpg5hbOkNl4DzAbEv'],
            CLIENT_AGF: '2KLxMWf5fNqJpIKXx3W7',
            CIRCULARIZACION: 'nplcOHNl5ZVl2olNfdJE',
            CERTIFICADO_SALDOS_MENSUAL: 'XRCMmWSO9h0DSdxBSjvC',
            PAF:'Yw1SuLNnfoMVBFYP3ju900',
            PAF_SEGUNDO_NIVEL:'bd8zzeW1wpGvAi4WzgHr',
        }
    },
    DATADOG: {
        applicationId: '98fc5360-4561-42a9-a47c-9fa716186874',
        clientToken: 'pub4f4afc086bcfab62673a4397eb6f667c',
        site: 'datadoghq.com',
        service: 'nueva-web-sura-asesores',
        sampleRate: 100,
        trackInteractions: true,
        env: 'PROD',
    },
    ENDPOINTS: {
        MS: {
            MS_LOGGER: `${urlMsCR}/logger`,
            MS_DATOS_TRANSACCIONALES: `${urlMsCR}/datos-transaccionales`
        },
        BFF: {
            BFF_ASESORES: `${Url}/bff-asesor/api`,
            BFF_CUSTOMERS_ASESORES: `${Url}/bff-customers/api`,
            BFF_FINANCIAL_REPORT: `${ClientBFF}/financial-report`,
            BFF_CLIENTUSERS: `${ClientBFF}/user`,
            BFF_CLIENTTRANSACTIONS: `${ClientBFF}/transactions`,
            BFF_CLIENTFINANCIAL: `${ClientBFF}/financial`,
            BFF_CLIENTBALANCES: `${ClientBFF}/products`,
            BFF_CLIENTTRACES: `${ClientBFF}/traces`,
            BFF_NOTIFICATIONS: `${ClientBFF}/notifications`,
            BFF_CLIENTCONFIG: `${ClientBFF}/config`,
	        BFF_ORDENES: `${Url}/bff-ordenes/ordenes`,
            BFF_USER: `${ClientBFF}/user`,
            BFF_CMS: `${ClientBFF}/cms`,
            BFF_DOCUMENTS: `${ClientBFF}/documents/documents`,
            BFF_TRANSVERSAL: `${UrlCR}/transversal`
        },
        SITIO_CLIENTE: 'https://privado.inversiones.sura.cl',
        ULTIMA_SESION: `${ClientBFF}/config/firestore`
    },
    FEATURE_FLAGS : [
        'HABILITAR_BOTON_WEALTH_PARTNERS',
        'HABILITAR_BOTON_PANELES_COMERCIALES_WP',
        'HABILITAR_BOTON_HISTORICO_INVERSIONES'
    ],
    FEATURE_VARS: [
        'MENU PORTAL ASESOR'
    ],
    MB_COLLECTIONS: {
        CHATBOT_ASESOR: 'chatbotasesores'
    },
    TAG_MANAGER_PROJECT_ID: 'GTM-KBWPHSS',
    CONTENTMANAGER: {
        EXECUTIVE_REPOSITORY: '/serviciocm/api/SURA__EJECUTIVOS'
    },
    LOGROCKET: {
        SITE_DOMAIN: 'inversiones.sura.cl',
        ENV: 'PROD'
    },
    BANKS_CODES: {
        SANTANDER: '37',
        BCI: '16'
    },
    AMBIENTE_PROTEGIDO:  false,
    WFI : {
        ACTIVO: false,
        URL: 'https://wfi-cl.surainvestments.com',
        PATHS: { AUTH: '/public/auth/validar-sesion' }
    }
};
